<template>
  <div :style="{ 'width': size, 'height': size }">
    <base-icon :width="size" :height="size" viewBox="0 0 24 24" class="icon--rotating">
      <icon-loading-three :fill="fill" :stroke="fill" />
    </base-icon>
  </div>
</template>


<script lang="js">
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconLoadingThree from '@/components/icons/icon-loading-three.vue';

export default {
  name: 'Loader',
  props: {
    size: {
      type: [Number, String],
      default: 18,
    },
    fill: {
      type: String,
      default: 'white',
    },
  },
  components: {
    BaseIcon,
    IconLoadingThree,
  },
};
</script>
